<template>
  <div
    v-if="!hidden"
    data-test-id="payment-methods-hub-component"
    :class="['PaymentMethodsHub', provider]"
  >
    <AddPaymentMethod
      v-if="isActionAdd || isFlowTrackingActionAdd"
      :locale="userDataLocale"
      :user-uuid="userDataUuid"
      :is-unfold-add-embedded="isUnfoldAddEmbedded"
      :is-open-modal="!isFlowTrackingEmbedded && isAddPaymentMethodModalOpen"
      :stage="flowTracking.stage"
      :provider="provider"
      :error-message="isFlowTrackingActionAdd ? showAddPaymentErrorMessage : ''"
      :show-helper-component="showProviderHelperComponent"
      data-test-id="add-payment-method-component"
      @update:candidate-payment-method="onCandidatePaymentMethod($event)"
      @on:error="onAddPaymentMethodError"
      @on:submit="onAddPaymentMethodSubmit"
      @on:close="onAddPaymentMethodClose"
    />

    <ui-loader v-if="isLoading && !selectInBackground" />

    <template v-else>
      <SelectPaymentMethod
        v-if="isActionSelect && currentProfile && !isUnfoldAddEmbedded"
        :force-unmount-cvc="forceUnmountSelectCvc"
        :locale="userDataLocale"
        :user-uuid="userDataUuid"
        :profile="currentProfile"
        :provider="provider"
        :hide-cvc="hideCvc"
        :select-locked="selectLocked"
        :show-helper-component="showProviderHelperComponent"
        :show-helper-component-loader="showHelperComponentLoader"
        :is-add-payment-method-modal-open="isAddPaymentMethodModalOpen"
        :is-add-payment-available="addOnSelectIsAvailable"
        :is-unfold-add-embedded="isUnfoldAddEmbedded"
        :select-in-background="selectInBackground"
        :is-payment-methods-collection-loading="isLoading"
        data-test-id="select-payment-method-component"
        @update:payment-method-selected="onPaymentMethodSelected"
        @update:cvc-candidate-payment-method="onCVCCandidatePaymentMethod"
        @update:no-default-payment-method="onNoDefaultPaymentMethod"
        @launch-add-payment-method="launchAddPaymentMethod(actions.select)"
      />

      <ListPaymentMethods
        v-if="isActionList && currentProfile"
        :profile="currentProfile"
        :is-loading="isListPaymentMethodsLoading"
        :is-deleting="isDeletingPaymentMethod"
        :is-delete-modal-open="isDeleteModalOpen"
        data-test-id="payment-methods-list-component"
        @launch-add-payment-method="launchAddPaymentMethod(actions.list)"
        @delete:payment-method="deletePaymentMethod"
        @update:set-default-payment-method="updateDefaultPaymentMethod"
        @update:delete-modal-open="isDeleteModalOpen = true"
        @update:delete-modal-close="isDeleteModalOpen = false"
        @update:deleting-default-single-payment-method="notifyMessage"
        @update:deleting-default-multiple-payment-method="notifyMessage"
      />
    </template>
    <FeedbackModalComponent
      v-model="paymentMethodFeedbackModal.isOpen"
      v-bind="paymentMethodFeedbackModal"
    />
  </div>
</template>

<script>
import includes from 'lodash/includes';
import get from 'lodash/get';
import isNull from 'lodash/isNull';

import { mapActions, mapGetters, mapState } from 'vuex';

import { nameSpace as UserDataNameSpace } from '@/vue/stores/UserData/UserDataStore';

import {
  DELAY, LOG_TYPE, logger, navigationErrorHandler,
} from '@emobg/web-utils';
import FeedbackModalComponent from '@/domains/Shared/components/FeedbackModal/FeedbackModalComponent';
import { genericErrorArgs } from '@/constants/defaultModalArgs';
import { useStore } from 'vuex-composition-helpers/dist';
import { NOTIFICATION_DEFAULT_PRIORITY } from '@/components/NotificationList/constants/notification.const';
import { commonImplementationHelper } from './commonImplementationHelper';
import { nameSpace as PaymentMethodsHubNameSpace } from './stores/PaymentMethodsHub';
import {
  ACTION_USED,
  ACTIONS,
  DEFAULT_PAYMENT_PROVIDER,
  DEFAULT_TRACKING_PROVIDER,
  HUB_REDIRECT_FLOW_STATUS,
  NOTIFICATION_MESSAGE_TYPES,
  RESPONSE_STATUS,
  SOURCE_STORE,
  STAGE,
} from './constants/paymentMethodsHub';

import AddPaymentMethod from './AddPaymentMethod';
import SelectPaymentMethod from './SelectPaymentMethod.vue';
import ListPaymentMethods from './ListPaymentMethods.vue';

import { PaymentMethodsHubComposable } from './composables/PaymentMethodsHubComposable';

import { onResponseParent } from './composables/onResponse';

export default {
  name: 'PaymentMethodsHub',

  components: {
    FeedbackModalComponent,
    AddPaymentMethod,
    ListPaymentMethods,
    SelectPaymentMethod,
  },

  props: {
    action: {
      default: ACTIONS.add,
      validator: value => includes(ACTIONS, value),
    },

    embedded: {
      type: Boolean,
      default: false,
    },

    addIsEmbedded: {
      type: Boolean,
      default: false,
    },

    addOnSelectIsAvailable: {
      type: Boolean,
      default: true,
    },

    provider: {
      type: String,
      default: DEFAULT_PAYMENT_PROVIDER,
    },

    hidden: {
      type: Boolean,
      default: false,
    },

    setAsDefault: {
      type: Boolean,
      default: true,
    },

    hideCvc: {
      type: Boolean,
      default: false,
    },

    selectLocked: {
      type: Boolean,
      default: false,
    },

    selectInBackground: {
      type: Boolean,
      default: false,
    },

    handleExternalResponse: {
      type: Object,
      default: () => {},
    },

    trackingProviderFn: {
      type: Function,
      default: () => {
        const defaultTrackingProvider = DEFAULT_TRACKING_PROVIDER;
        return import(`${defaultTrackingProvider}`);
      },
    },
  },

  setup(props) {
    const { saveResponse, storeData } = commonImplementationHelper();
    const {
      addNewPaymentMethod,
      getProfileInfo,
      getPaymentMethodsCollection,
      getPaymentMethodPayload,
      parseResponse,
      removePaymentMethod,
      setDefaultPaymentMethod,
      storeRedirectPaymentMethod,
      storage,
    } = PaymentMethodsHubComposable(storeData);

    const setupProps = {
      props,
      locale: storeData.locale,
    };

    const { onComposableResponse } = onResponseParent(storeData);
    const store = useStore();
    const trackingProvider = props.trackingProviderFn;

    const providerVersionRegex = /\d/;

    return {
      addNewPaymentMethod,
      getProfileInfo,
      getPaymentMethodsCollection,
      getPaymentMethodPayload,
      parseResponse,
      removePaymentMethod,
      setDefaultPaymentMethod,
      storeRedirectPaymentMethod,
      storage,
      onComposableResponse,

      saveResponse,
      setupProps,
      trackingProvider,
      store,

      providerVersionRegex,
    };
  },

  data() {
    return {
      isAddPaymentMethodModalOpen: false,
      addPaymentInternalErrorMessage: null,
      candidatePaymentMethod: null,
      flowTracking: {
        currentAction: null,
        previousAction: null,
        stage: null,
        isEmbedded: false,
      },
      unfoldAddEmbedded: false,
      isRedirected: false,

      showProviderHelperComponent: false,
      showHelperComponentLoader: false,
      isLoading: false,
      currentProfile: null,
      actions: null,
      isListPaymentMethodsLoading: false,
      isDeleteModalOpen: false,
      deletingPaymentMethod: {},
      isDeletingPaymentMethod: false,
      paymentMethodFeedbackModal: {
        isOpen: false,
      },
      paymentMethodsCollection: null,
      internalPaymentData: null,
      forceUnmountSelectCvc: false,
    };
  },

  computed: {
    ...mapState(UserDataNameSpace, {
      userDataLocale: state => get(state, 'userData.locale'),
      userDataUuid: state => get(state, 'userData.uuid'),
    }),

    ...mapGetters(PaymentMethodsHubNameSpace, [
      'getDefaultPaymentMethodUUID',
      'getPaymentMethodByUUID',
    ]),

    ...mapState(PaymentMethodsHubNameSpace, [
      'source', 'hubAction',
    ]),
    customerServiceOpeningHours() {
      return this.currentProfile.currentCSOperator.customer_service_opening_hours;
    },
    showAddPaymentErrorMessage() {
      return isNull(this.addPaymentInternalErrorMessage) ? '' : this.addPaymentInternalErrorMessage;
    },

    isFlowTrackingActionAdd() {
      return this.flowTracking.currentAction === ACTIONS.add;
    },

    isFlowTrackingEmbedded() {
      return this.flowTracking.isEmbedded;
    },

    isUnfoldAddEmbedded() {
      return this.unfoldAddEmbedded;
    },

    isActionAdd() {
      return this.action === ACTIONS.add;
    },

    isActionSelect() {
      return this.action === ACTIONS.select;
    },

    isActionList() {
      return this.action === ACTIONS.list;
    },

    contextData() {
      return {
        flowTracking: this.flowTracking,
        isRedirected: this.isRedirected,
      };
    },
  },

  watch: {
    hidden(value) {
      if (!value && this.action === ACTIONS.add) {
        this.isAddPaymentMethodModalOpen = true;
      }
    },

    handleExternalResponse(response) {
      const parsedResponse = this.parseResponse(response);

      this.internalPaymentData = parsedResponse;

      this.flowTracking.currentAction = ACTIONS.payment;
      this.onResponse(parsedResponse);
    },

    async source(_, oldSource) {
      if (oldSource) {
        await this.refreshPaymentMethodsCollection();
      }
    },

    hubAction({ action, value }) {
      this[action](value);
    },
  },

  async created() {
    await this.refreshPaymentMethodsCollection();

    if (!this.embedded) {
      this.isAddPaymentMethodModalOpen = true;
    }

    this.flowTracking.currentAction = this.action;
    this.flowTracking.isEmbedded = this.embedded;

    this.actions = ACTIONS;
  },

  methods: {
    ...mapActions(PaymentMethodsHubNameSpace, ['savePaymentMethodsCollection', 'updateSource']),

    onAddPaymentMethodError(addPaymentMethodError) {
      this.addPaymentInternalErrorMessage = addPaymentMethodError;
    },

    onCandidatePaymentMethod(method) {
      this.candidatePaymentMethod = {
        ...method.paymentMethod,
      };

      this.flowTracking = {
        currentAction: ACTIONS.add,
        stage: STAGE.formValid,
      };
      this.addPaymentInternalErrorMessage = null;
    },

    async onAddPaymentMethodSubmit() {
      this.setStage(STAGE.submitting);

      let response = null;

      try {
        response = await this.addNewPaymentMethod(this.candidatePaymentMethod, this.flowTracking, this.provider);
      } catch (error) {
        const errorResponse = error.response;

        const errorMsg = errorResponse.data.message || errorResponse.data.data.message || error.message;

        this.onAddPaymentMethodError(errorMsg);
        this.setStage(STAGE.refused);
        return;
      }

      if (
        response.providerStatus === RESPONSE_STATUS.pending
        && !this.$router.history.current.fullPath.includes(HUB_REDIRECT_FLOW_STATUS.toRedirect)
      ) {
        this.$router.push({ query: { status: HUB_REDIRECT_FLOW_STATUS.toRedirect } }).catch(navigationErrorHandler);
      }

      this.currentDefaultPaymentMethodUuid = this.getDefaultPaymentMethodUUID(this.currentProfile);

      this.onResponse(response);
    },

    async linkDefaultPaymentMethodOnAdd(originalResponse) {
      this.paymentMethodsCollection = await this.refreshPaymentMethodsCollection();

      if (originalResponse.actionUsed !== ACTIONS.add || (this.paymentMethodsCollection.length > 1 && !this.setAsDefault)) {
        return;
      }

      this.isListPaymentMethodsLoading = true;

      if (this.currentProfile.isBusinessProfile && !this.currentProfile.isBusinessInCompanyPays) {
        /** in admin in employeePays
        * set previous payment method in B2C profile
        * and set new payment method in B2B profile
        */
        await Promise.all([
          await this.setDefaultPaymentMethod(
            this.currentDefaultPaymentMethodUuid,
            this.provider,
            this.currentProfile.oppositeProfile.uuid,
          ),
          await this.setDefaultPaymentMethod(originalResponse.paymentMethodUuid, this.provider),
        ]);
      } else if (this.setAsDefault || (this.paymentMethodsCollection.length === 1 && !this.setAsDefault)) {
        await this.setDefaultPaymentMethod(originalResponse.paymentMethodUuid, this.provider);
      }

      await this.refreshPaymentMethodsCollection().then(() => {
        this.isListPaymentMethodsLoading = false;
      });
    },

    async parentActionCallback(action, value = null, asyncFn) {
      const availableActions = [
        'resetRedirect',
        'setStage',
        'onAddPaymentMethodClose',
        'onAddPaymentMethodError',
        'refreshPaymentMethodsCollection',
        'toggleHelperComponent',
        'toggleHelperComponentLoader',
        'resetFlow',
        'resetRedirect',
        'saveResponse',
        '$nextTick',
        'linkDefaultPaymentMethodOnAdd',
        'trackInternalPayment',
      ];

      if (!availableActions.includes(action)) {
        logger.message(`action ${action} not available!`, LOG_TYPE.error);
        return;
      }

      if (asyncFn) {
        await this[action](value);
      } else {
        this[action](value);
      }
    },

    commonEmit(emitTarget, emitContent) {
      this.$emit(emitTarget, emitContent);
    },

    onResponse(response) {
      if (!this.isRedirected) {
        this.addPaymentInternalErrorMessage = null;
      }

      const hierarchy = this.$parent;

      const composablePayload = {
        originalResponse: response,
        parentActionCallback: this.parentActionCallback,
        contextData: this.contextData,
        commonEmit: this.commonEmit,
        setupProps: this.setupProps,
        hierarchy,
      };

      this.onComposableResponse(composablePayload);
    },

    setStage(stage) {
      this.flowTracking.stage = stage;
    },

    toggleHelperComponent(value) {
      this.showProviderHelperComponent = value;

      this.$emit('update:helper-component-active', value);
    },

    toggleHelperComponentLoader(value) {
      this.showHelperComponentLoader = value;
    },

    async refreshPaymentMethodsCollection() {
      this.isLoading = true;
      this.currentProfile = this.getProfileInfo();

      const paymentMethodsCollection = await this.getPaymentMethodsCollection(this.provider);

      const payload = {
        profile: this.currentProfile,
        paymentMethodsCollection,
      };

      await this.savePaymentMethodsCollection(payload);

      await this.updateSource(SOURCE_STORE.hub);

      this.isLoading = false;

      return paymentMethodsCollection;
    },

    async onPaymentMethodSelected(paymentMethodUUID, isCard) {
      if (this.setAsDefault && isCard && !this.selectLocked && !this.selectInBackground) {
        await this.setDefaultPaymentMethod(paymentMethodUUID, this.provider);
      }

      if (this.selectInBackground || (this.selectLocked && this.hideCvc)) {
        const paymentMethod = await this.getPaymentMethodByUUID(paymentMethodUUID, this.currentProfile);

        const payload = {
          isValid: true,
          paymentMethod: {
            ...paymentMethod,
            profile: this.currentProfile,
          },
        };

        const paymentMethodPayload = this.getPaymentMethodPayload(payload);

        this.paymentMethodEmitCollector(paymentMethodPayload);
      }

      this.$emit('on:payment-method-selected');
    },

    onCVCCandidatePaymentMethod(CVCCandidatePaymentMethod) {
      const paymentMethodPayload = this.getPaymentMethodPayload(CVCCandidatePaymentMethod);

      this.paymentMethodEmitCollector(paymentMethodPayload);
    },

    onNoDefaultPaymentMethod() {
      this.$emit('update:no-default-payment-method');
    },

    paymentMethodEmitCollector(payload) {
      this.$emit('update:payment-method-valid', payload);
    },

    launchAddPaymentMethod(action) {
      this.flowTracking = {
        ...this.flowTracking,
        currentAction: ACTIONS.add,
        isEmbedded: false,
        previousAction: action,
      };

      if (this.addIsEmbedded) {
        this.unfoldAddEmbedded = true;
      }

      this.isAddPaymentMethodModalOpen = true;

      this.$emit('update:launch-add-payment-method');

      this.trackLaunchAddPaymentMethod();
    },

    resetFlow() {
      this.flowTracking.stage = null;
      this.candidatePaymentMethod = null;
      this.addPaymentInternalErrorMessage = null;

      if (this.flowTracking.previousAction) {
        this.flowTracking.currentAction = this.flowTracking.previousAction;
        this.flowTracking.previousAction = null;
        return;
      }

      this.flowTracking.currentAction = null;
      this.internalPaymentData = null;

      if (this.forceUnmountSelectCvc) {
        setTimeout(() => {
          this.forceUnmountSelectCvc = false;
        }, DELAY.long);
      }
    },

    resetRedirect() {
      if (this.isRedirected) {
        this.isRedirected = false;
        this.storage.remove(ACTION_USED);
      }
    },

    async onAddPaymentMethodClose() {
      this.isAddPaymentMethodModalOpen = false;

      if (this.addIsEmbedded) {
        this.unfoldAddEmbedded = false;
      }

      if (this.embedded) {
        await this.refreshPaymentMethodsCollection();
      }

      this.$emit('on:close');
      this.resetFlow();
    },

    async deletePaymentMethod(paymentMethodUUID) {
      this.isDeletingPaymentMethod = true;
      this.isListPaymentMethodsLoading = true;

      try {
        await this.removePaymentMethod(paymentMethodUUID, this.provider);
      } catch (error) {
        this.showRefusedFeedback();
      }
      await this.refreshPaymentMethodsCollection();

      this.isListPaymentMethodsLoading = false;
      this.isDeletingPaymentMethod = false;
      this.isDeleteModalOpen = false;
    },

    async updateDefaultPaymentMethod(paymentMethodUUID) {
      this.isListPaymentMethodsLoading = true;

      await this.setDefaultPaymentMethod(paymentMethodUUID, this.provider);
      await this.refreshPaymentMethodsCollection();

      this.isListPaymentMethodsLoading = false;
      this.notifyMessage({
        message: this.$t('payments.payment_methods.notifications.change_default_payment_method.success'),
        type: NOTIFICATION_MESSAGE_TYPES.success,
      });
    },

    showRefusedFeedback() {
      const { currentCSOperator } = this.currentProfile;
      this.paymentMethodFeedbackModal = {
        ...genericErrorArgs(this.$t),
        title: this.$t('notifications.whooops'),
        description: this.$t('modal.remove_payment_method.error.description', {
          customer_service_phone: currentCSOperator.customer_service_phone,
          customer_service_opening_hours: currentCSOperator.customer_service_opening_hours,
        }),
        primaryCallToActionText: this.$t('modal.cancel_booking.error.try_again'),
        primaryCallToAction: () => { this.paymentMethodFeedbackModal.isOpen = false; },
        isOpen: true,
      };
    },

    notifyMessage(notification) {
      if (notification.type === NOTIFICATION_MESSAGE_TYPES.error) {
        this.$emit('on:error-message', notification.message, NOTIFICATION_DEFAULT_PRIORITY.exceptionalPriority);
      } else if (notification.type === NOTIFICATION_MESSAGE_TYPES.success) {
        this.$emit('on:success-message', notification.message);
      }
    },

    async trackLaunchAddPaymentMethod() {
      const { paymentMethodsHubTracking } = await this.trackingProvider();
      const { launchAddPaymentMethodTrackEvent } = await paymentMethodsHubTracking(this.store);
      const { trackingLibrary, launchAddPaymentMethodEvent } = launchAddPaymentMethodTrackEvent();

      trackingLibrary(launchAddPaymentMethodEvent);
    },

    trackInternalPayment(response) {
      if (response.providerStatus === RESPONSE_STATUS.refused && this.internalPaymentData.providerStatus === RESPONSE_STATUS.challenge) {
        this.forceUnmountSelectCvc = true;
      }
    },
  },
};
</script>
