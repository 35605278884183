<template>
  <div class="ListPaymentMethodsItem">
    <div :class="`d-flex py-2 justify-content-between ${isOppositeDefault ? ' opposite-default' : ''}`">
      <div class="d-flex align-items-center w-100">
        <ui-radio
          v-if="!isOppositeDefault"
          :key="defaultPaymentMethodKey"
          :value="isLoading ? '' : paymentMethod.uuid"
          :option="defaultPaymentMethodUuid"
          :disabled="isDefault"
          name="defaultPaymentMethod"
          class="mr-3 align-self-center"
          data-test-id="default_payment_method-radio"
          @changevalue="$emit('on:change-payment-method-default', index)"
        />

        <ui-icon
          :icon="paymentMethodIcon(paymentMethod)"
          class="mt-n2"
          data-test-id="payment_method_type-icon"
        />

        <span
          class="mt-n1 ml-3 py-2"
          data-test-id="payment_method-number"
        >
          {{ formatPaymentMethod(paymentMethod.type, paymentMethod.number) }}
        </span>
        <ui-badge
          v-if="isDefault || isOppositeDefault"
          :color="isOppositeDefault ? COLORS.accent : COLORS.secondary"
          :text="$t('payments.default_payment')"
          class="mt-n2 ml-3"
        />
      </div>
      <div
        v-if="!isLoading"
        class="icon-arrow--wrapper"
      >
        <ui-icon
          :icon="isOpen ? ICONS.arrowUp : ICONS.arrowDown"
          :color="GRAYSCALE.inkLighter"
          class="icon-arrow"
          @click="$emit('on:open-payment-method-detail', index)"
        />
      </div>
    </div>
    <div v-if="isOpen">
      <div class="d-flex w-100 mb-3">
        <ui-text-input
          v-if="isExpiryDateInputVisible"
          :value="paymentMethod.expiry"
          :label="$t('settings.driving_license.expire')"
          disabled
          name="expiring_date"
        />
        <ui-text-input
          v-if="isCardHolderInputVisible"
          :label="$t('card_holder.label')"
          :value="paymentMethod.holder"
          disabled
          name="card_holder"
        />
      </div>
      <div
        v-if="!isOppositeDefault"
        class="w-100 d-flex justify-content-end mb-3"
      >
        <ui-button
          v-if="paymentMethod.uuid && !isLoading"
          v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.DESTRUCTIVE })"
          :face="FACES.outline"
          :size="SIZES.small"
          class="text-right"
          data-test-id="payment_method_delete-button"
          @clickbutton="onDeletePaymentMethod"
        >
          {{ $t('payments.remove_payment_method') }}
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import { PAYMENT_TYPES } from '@/domains/Shared/Payments/PaymentMethodsHub/constants/paymentMethodsHub';
import { maskCardNumber } from '@emobg/web-utils';
import { computed } from 'vue';
import { useTheme } from '@/composable/Theme/useTheme';

import { maskIbanNumber, paymentMethodIcon } from './PaymentMethodsUtils';
export default {
  name: 'ListPaymentMethodsItem',
  props: {
    index: {
      type: Number,
      required: true,
    },
    paymentMethod: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    defaultPaymentMethodKey: {
      type: Number,
      required: true,
    },
    defaultPaymentMethodUuid: {
      type: String,
      required: true,
    },
    defaultOppositePaymentMethodUuid: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const inputVisibilityTypes = {
      card: PAYMENT_TYPES.card,
      sepa: PAYMENT_TYPES.sepa,
      iban: PAYMENT_TYPES.bankTransfer,
    };
    const isExpiryDateInputVisible = computed(() => (inputVisibilityTypes.card === get(props, 'paymentMethod.payment_method_type')));
    const isCardHolderInputVisible = computed(() => (!!inputVisibilityTypes[get(props, 'paymentMethod.payment_method_type')]));
    const isDefault = computed(() => props.paymentMethod.default);
    const isOppositeDefault = computed(() => props.paymentMethod.isOpposite);
    const { fetchButtonSpecs } = useTheme();
    return {
      isDefault,
      isOppositeDefault,
      isExpiryDateInputVisible,
      isCardHolderInputVisible,
      inputVisibilityTypes,
      paymentMethodIcon,
      maskIbanNumber,
      fetchButtonSpecs,
    };
  },
  created() {

  },
  methods: {
    onDeletePaymentMethod() {
      this.$emit('delete:payment-list-item', {
        index: this.index,
        isDefault: this.paymentMethod.uuid === this.defaultPaymentMethodUuid,
      });
    },
    formatPaymentMethod(paymentMethodNumberType, paymentMethodNumber) {
      const getPaymentMethodLabel = {
        [PAYMENT_TYPES.bankTransfer]: () => this.$t('common.payment_method.bank_transfer'),
        [PAYMENT_TYPES.iban]: () => maskIbanNumber(paymentMethodNumber),
      }[paymentMethodNumberType];

      return getPaymentMethodLabel?.() ?? maskCardNumber(paymentMethodNumber);
    },
  },
};
</script>
<style lang="scss">
  @import "~@emobg/sass/colors/variables";
  $disabled-color: map-get($motion-grayscale, ground);

  .ListPaymentMethodsItem {
    .opposite-default {
      color: $disabled-color;
    }

    .icon-arrow--wrapper {
      cursor: pointer;
    }

    ui-text-input input {
      color: map-get($motion-grayscale, ink-light) !important;
    }

    ui-text-input[name="expiring_date"] {
      flex-grow: 1;
      max-width: 30%;
    }

    ui-text-input[name="card_holder"] {
      flex-grow: 4;
    }

    ui-text-input[name="expiring_date"] + ui-text-input[name="card_holder"] {
      margin-left: 1rem;
    }
  }
</style>
